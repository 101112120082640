<template>
    <div>
        <a-row type="flex" :gutter="[2,2]">
            <a-col class="flex-card-producto" flex="1 1 90%">
                <a-input 
                    size="large" 
                    placeholder="Haz clic aqui para buscar un producto" 
                    onfocus="myFunction(this)"
                    v-model="search"
                    @keypress="handleBusquedaListaProductos($event)"
                />
            </a-col>
            <a-col class="flex-card-codigo" flex="1 1 10%">
                <div class="buttons">
                    <a-button 
                        type="dashed" 
                        size="large" 
                        block
                        @click="preCargaDeProductos"
                        >
                        <a-icon type="reload" :style="{ fontSize: '20px' }"/>
                    </a-button>
                </div>  
            </a-col>
        </a-row>
        <div class="products">
            <a-row :gutter="16" type="flex" justify="start" align="top">
                <a-col :xs="24" :sm="12" :lg="8" v-for="(value, index) in lista_producto_scroll" :key="index">
                    <a-card class="product-card" hoverable @click="handleChangeListaProductos(value)">
                        <div class="product-content">
                            <div class="product-image-container">
                                <img 
                                    loading="lazy" 
                                    :src="(value.imagen) ? value.imagen : imagenDefault" 
                                    class="product-image" 
                                    @error="handleImageError"
                                />
                            </div>
                            <div class="product-info">
                                <p>
                                    <span class="tituloSaldoFisico">Stock</span>
                                    <span class="saldoFisico"> {{ value.saldoFisico }}</span>
                                </p>
                                <p class="detalle">{{ utils.truncarCadenaConPalabraCompleta(value.detalle, 115) }}</p>
                                <p class="precioCompra">{{ utils.formatMoney(value.precioCompra, config.compradecimales) }} {{ config.monedasimbolo }}</p>
                            </div>
                        </div>
                    </a-card>
                </a-col>
            </a-row>
        </div>
    </div>
</template>

<script>
	import * as utils from '@/utils/utils'
	import { mapGetters, mapActions } from 'vuex'
    import { 
		STORAGE_LISTA_SCROLL_PRODUCTO
	} from '@/utils/constants'
	import * as database from '@/utils/database'

	function escapeRegExp (string) {
		return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
	}

	export default {

        props: {
			almacenid: { type: Number, required: true },
			tipocambioid: { type: Number, required: true },
			listacambio: { type: Array, required: true }
		},

		data () {
			return {
                imagenDefault: (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/producto.jpg' : '/static/assets/images/producto.jpg',
                utils,
				spinnerloading: false,
                valueProductoScroll: undefined,
                lista_producto_scroll: [],
                spinnerScroll: false,
                loading: false,
				productoLocalStorage: [],
                search: ''
			}
		},

		mounted () {
			this.preCargaDeProductos()
        },

		computed: {
            ...mapGetters('configuracion', ['config'])
        },

		methods: {

			preCargaDeProductos () {
				if (localStorage.getItem('cargaDeProductosSinStock') === 'CARGADO') {
					this.cargaDeProductosOffline()
				}

				const interval2 = setInterval(() => {
					if (localStorage.getItem('cargaDeProductosSinStock') === 'CARGADO') {
						this.cargaDeProductosOffline()
					}
				}, 2000)

				setInterval(() => {
					clearInterval(interval2)
				}, 8000)
			},

			async verificarProductos () {
				console.log('verificarProductos')
				this.spinnerloading = true
				this.preCargaDeProductos()
			},

			async cargaDeProductosOffline () {
				try {
					console.log('cargaDeProductos', 'Obteniendo productos offline desde IndexedDB sin Stock')
					const listaproductos = await this.obtenerDatosDeIndexedDB()
					if (listaproductos.length > 150) {
						this.lista_producto_scroll = listaproductos.slice(-100).reverse()
					} else {
						this.lista_producto_scroll = listaproductos
					}
					this.spinnerloading = false
				} catch (error) {
					console.error('Error al obtener la información:', error)
				}
			},

			async obtenerDatosDeIndexedDB () {
				return await new Promise((resolve, reject) => {
					database.getData(STORAGE_LISTA_SCROLL_PRODUCTO)
					.then(array => {
						resolve(array)
					})
					.catch(error => {
						console.error('Error al obtener el array de IndexedDB:', error)
						reject(error) // Rechaza la promesa con el error obtenido
					})
				})
			},

			async handleBusquedaListaProductos (value) {
					console.log('Búsqueda interna de productos para cotización y consultas')
					try {
						const listaproductos = await this.obtenerDatosDeIndexedDB()
						if (!listaproductos || listaproductos.length === 0) {
							utils.openNotificationWithIcon('info', '¡Atención!', 'La lista de productos está vacía. Por favor, vuelve a cargarla.', 'topRight')
							return
						}

						const escapedValue = escapeRegExp(this.search)
						const regex = new RegExp(escapedValue, 'giu')
						const resultado = listaproductos.filter(element => element.detalle.match(regex))

						if (resultado.length > 150) {
							this.lista_producto_scroll = resultado.slice(-100).reverse()
						} else {
							this.lista_producto_scroll = resultado
						}
					} catch (error) {
						console.error('Error al obtener la información:', error)
					}
			},

			async handleChangeListaProductos (value) {
                const opciones = {
                    productoID: value.producto_id,
                    almacenID: this.almacenid,
                    listaTipoCambio: this.listacambio,
                    tipocambioID: this.tipocambioid,
                    compraDecimal: this.config.compradecimales
                }

                const producto = await this.buscarProductoEnLista(opciones)

                if (!producto) {
                    this.mostrarAlertaProductoNoEncontrado()
                    return
                }
				
                const data = await this.crearProducto(producto, opciones)
                this.$emit('successScrollProductoCompra', data)
            },

            async buscarProductoEnLista ({ productoID, almacenID }) {
				try {
					const listaproductos = await this.obtenerDatosDeIndexedDB()
					const producto = listaproductos.find(producto => producto.producto_id === parseInt(productoID) && producto.almacenId === parseInt(almacenID))
					
					if (this.config.istotallistaproductoventa === 'ACTIVADO') {
						this.valueProductoScroll = `${producto.nombre}`
					} else if (this.config.istotallistaproductoventa === 'DESACTIVADO') {
						if (this.config.ismarcaizquierdalistaventa === 'ACTIVADO') {
							this.valueProductoScroll = `${producto.codigo} ${producto.nombre} ${producto.medida} ${producto.unidad} ${producto.marca}`
						} else if (this.config.ismarcaizquierdalistaventa === 'DESACTIVADO') {
							this.valueProductoScroll = `${producto.codigo} ${producto.marca} ${producto.nombre} ${producto.medida} ${producto.unidad}`
						}
					}

					return producto
				} catch (error) {
					console.error('Error al obtener la información:', error)
				}
			},

            async crearProducto (producto, opciones) {
                return {
                    producto_id: producto.producto_id,
                    codigo: producto.codigo,
                    nombre: producto.nombre,
                    medida: producto.medida,
                    marca: producto.marca,
                    saldoFisico: producto.saldoFisico,
                    porcentajeUtilidad: producto.porcentajeUtilidad,
                    elaboracion: producto.elaboracion,
                    vencimiento: producto.vencimiento,
                    proveedorID: producto.proveedorID,
					proveedor: producto.proveedor,
                    descuentoCompra: utils.formatMoney(producto.descuentoCompra, opciones.compraDecimal, '.', ''),
                    almacenId: producto.almacenId,
                    precioCompra: utils.formatMoney(producto.precioCompra, opciones.compraDecimal, '.', ''),
                    cantidad: 1,
                    subtotal: utils.formatMoney(parseInt(producto.cantidad) * parseFloat(producto.precioCompra), opciones.compraDecimal, '.', ''),
                    importeTotalMoneda: utils.formatMoney(parseInt(producto.cantidad) * parseFloat(producto.precioCompra), opciones.compraDecimal, '.', ''),
                    imagen: producto.imagen
                }
            },

			mostrarAlertaProductoNoEncontrado () {
                utils.openNotificationWithIcon('info', 'Mensaje : ', 'No existe el Producto Seleccionado en nuestra Sucursal o el Producto no tiene stock en el almacen !!!', 'topRight')
            },

			mostrarAlertaPrecioCero () {
				utils.openNotificationWithIcon('info', 'Mensaje : ', 'Por favor agregue Precios de Ventas al Producto segun la Sucursal donde pertenezca...', 'topRight')
			},

            handleImageError (event) {
                event.target.src = this.imagenDefault
            },

            agregarProducto (value) {
                console.log(value)
            }
		}

	}
</script>

<style>

.buttons {
  display: flex;
  justify-content: space-between;
}

.products {
  margin-top: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 590px;
}

.product-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;  /* Permitir que crezca según el contenido */
    flex-shrink: 1; /* Permitir que se reduzca según el contenido */
    overflow: hidden; /* Evitar el desbordamiento */ /* Ajustar según sea necesario */
}

.product-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.product-image-container {
  position: relative;
  width: 100%;
  padding-top: 100%; /* Mantener el aspecto cuadrado */
  margin-bottom: 10px;
  overflow: hidden;
}

.product-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Asegura que la imagen cubra todo el contenedor */
  display: block; /* Elimina cualquier espacio adicional debajo de la imagen */
}

.product-info {
  color: black;
  text-align: center;
}

.detalle {
    text-overflow: ellipsis;
}

.tituloSaldoFisico {
    font-size:11px;
    color:#0A6B00;
}

.saldoFisico {
    font-size:17px;
    font-weight:700;
    color:#0A6B00;
}

.precioCompra {
    font-size:19px;
    font-weight:700;
    color:var(--element);
}
</style>