<template>
	<div>
        <template v-if="tablaproducto.length > 0">
            <div v-for="(item, index) in tablaproducto" :key="index" class="invoice-item">
                <div class="item-details">
                    <span class="item-name">
                        <p>
                            {{ item.nombre }}<br>
                            <span class="nombrePrecioCompra">P.Compra</span> <span class="precioCompraCarrito">{{ utils.formatMoney(item.precioCompra, config.compradecimales) }}</span>
                        </p>
                    </span>
                    <div class="item-quantity">
                        <a href="#" @click.prevent="decreaseQuantity(item, index)"><a-icon type="minus" class="pointer decrease-btn"/></a>
                        <span>{{ item.cantidad }}</span>
                        <a href="#" @click.prevent="increaseQuantity(item, index)"><a-icon type="plus" class="pointer increase-btn" /></a>
                    </div>
                    <div class="item-actions">
                        <a-icon type="edit" class="pointer" @click="editItem(item, index)" :style="{ fontSize: '25px', color: '#00687F' }"/>
                        <a-icon type="delete" class="pointer" @click.prevent="deleteItem(index)" :style="{ fontSize: '25px', color: '#BF0000' }"/>
                    </div>
                </div>

                <div class="item-details-mobile">
                    <img 
                        loading="lazy" 
                        :src="(item.imagen) ? item.imagen : imagenDefault" 
                        class="item-image" 
                        @error="handleImageError"
                    />
                    <span class="item-name">
                        {{ item.nombre }}<br>
                        <div class="item-quantity">
                            <a href="#" @click.prevent="decreaseQuantity(item, index)"><a-icon type="minus" class="pointer decrease-btn"/></a>
                            <span>{{ item.cantidad }}</span>
                            <a href="#" @click.prevent="increaseQuantity(item, index)"><a-icon type="plus" class="pointer increase-btn" /></a>
                        </div>
                    </span>
                    <div class="item-total-mobile" @click="editItem(item, index)">{{ item.subtotal }} &nbsp;<a-icon type="setting" :style="{ color: '#555555', marginTop: '3px' }"/></div>
                </div>

                <div class="item-total">{{ item.subtotal }}</div>
            </div>
        </template>

        <template v-if="tablaproducto.length === 0">
            <a-empty 
                :image-style="{
                    height: '280px',
                }"
                description="Aquí verás los productos que elijas en tu próxima compra" 
            />
        </template>

        <!-- EDITAR PRECIOS DE VENTA -->
        <a-modal
            v-model="openModalPrecioVenta"
            title="ASIGNAR PRECIOS DE VENTA AL PRODUCTO"
            :closable="true"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="920"
            :zIndex="1040"
            >

            <div class="mb-12">
                <ModuloPrecioVenta 
                    :monedasimbolo="config.monedasimbolo"
                    :utilidadporcentaje="config.utilidadporcentaje"
                    :preciocifradecimal="config.preciocifradecimal"
                    :preciocifraentera="config.preciocifraentera"
                    :precioreglatres="config.precioreglatres"
                    :almacenid="this.consulta.almacenID"
                    :productoid="this.consulta.productoID"
                    :proveedorid="this.consulta.proveedorID"
                    :utilidad="this.consulta.utilidad"
                    :producto="this.consulta.producto"
                    :preciocompra="this.consulta.precioCosto"
                />
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="openModalPrecioVenta = false">
                    Cerrar
                </a-button>
            </template>
        </a-modal>
        <!-- EDITAR PRECIOS DE VENTA -->

        <!-- ACTUALIZAR VENCIMIENTO -->
        <a-modal
            v-model="showModalActualizarVencimiento"
            title="Actualizar fecha de Vencimiento"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="560"
            :zIndex="1049"
            >

            <div class="row mb-12">
                <ComponenteActualizarVencimiento
                    :producto="this.consulta.producto"
                    @successActualizarVencimiento="dataSuccessActualizarVencimiento"
                />
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="showModalActualizarVencimiento = false">
                    Cancelar
                </a-button>
            </template>
        </a-modal>
        <!-- ACTUALIZAR VENCIMIENTO -->

        <a-drawer
            title="Editar Compra"
            placement="right"
            :closable="false"
            :mask="true"
            :maskClosable="true"
            :destroyOnClose="true"
            :visible="visibleDrawer"
            :zIndex="1030"
            :width="240"
            @close="onClose"
            >
            <a-row>
                <a-card>
                    <a-card-meta>
                        <p slot="title">
                            <span style="font-size:18px; color:green;">Stock: {{ producto.saldoFisico }}</span>
                            <a href="#" style="color:#BF0000; margin-left: 30px;" @click.prevent="deleteItem(producto.index)"><a-icon type="delete" class="pointer" :style="{ fontSize: '14px', color: '#BF0000' }"/> Eliminar</a>
                        </p>
                    </a-card-meta>

                    <span style="font-size:14px;"> {{ producto.codigo }} {{ producto.nombre }} {{ producto.medida }} {{ producto.marca }} <br></span>

                    <a-row>
                        <a-divider orientation="left">
                            Cantidad
                        </a-divider>
                        <a-col :span="15" :offset="4">
                            <a-input-number 
                                size="large"
                                :value="producto.cantidad"
                                @change="editCantidad"
                                style="width: 100%"
                            />
                        </a-col>
                    </a-row>

                    <a-row>
                        <a-divider orientation="left">
                            Precio Compra
                        </a-divider>
                        <a-col :span="15" :offset="4">
                            <a-input-number 
                                size="large" 
                                :value="producto.precioCompra"
                                @change="editPrecioCompra"
                                style="width: 100%"
                            />
                        </a-col>
                    </a-row>

                    <a-row>
                        <a-divider orientation="left">
                            Descuento
                        </a-divider>
                        <a-col :span="15" :offset="4">
                            <a-input-number 
                                size="large" 
                                :value="producto.descuentoCompra" 
                                @change="editItemDescuentoCompra"
                                style="width: 100%"
                            />
                        </a-col>
                    </a-row>

                    <div class="drawer-total">
                          <div class="drawer-totals-left">
                            <p>Subtotal:</p>
                            <p>Descuento:</p>
                            <p>Total:</p>
                          </div>
                          <div class="drawer-totals-right">
                            <p>{{ producto.subtotal }}</p>
                            <p>{{ producto.descuentoCompra }}</p>
                            <p>{{ producto.importeTotalMoneda }}</p>
                          </div>
                    </div>
                </a-card>

                <a-button 
                    v-if="$can('adquisicion.agregar.modificar.precio.de.venta')"
                    block
                    @click="abrirModalAsignarPrecios(producto)" 
                    :key="buttonKeyPrecioVenta"
                    type="dashed" 
                    >
                    <a-icon type="form" :style="{ fontSize: '22px', color: '#006D78' }"/> Asignar Precio Venta
                </a-button>

                <a-button 
                    v-if="$can('adquisicion.modificar.fecha.de.vencimiento')"
                    block
                    @click.prevent="abrirModalActualizarVencimiento(producto)"
                    type="dashed" 
                    >
                    <a-icon type="calendar" :style="{ fontSize: '22px', color: '#006D78' }"/> Fecha de Vencimiento
                </a-button>
            </a-row>
        </a-drawer>
    </div>
</template>

<script>
	import * as utils from '@/utils/utils'
	import { mapGetters, mapActions } from 'vuex'
    import ModuloPrecioVenta from '../../pages/Inventario/Modulos/ModuloPrecioVenta.vue'
    import ComponenteActualizarVencimiento from '../../pages/Inventario/Components/ComponenteActualizarVencimiento.vue'

	export default {

        props: {
			listaproducto: { type: Object, required: true },
            estadomodulocompra: { type: String, required: true },
            nuevaventanacompraid: { type: Number, required: true },
            almacenID: { type: Number, required: false },
            proveedorID: { type: Number, required: false }
		},

        components: {
            ModuloPrecioVenta,
            ComponenteActualizarVencimiento
        },

		data () {
			return {
                imagenDefault: (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/producto.jpg' : '/static/assets/images/producto.jpg',
                utils,
                valueCantidad: null,
                valuePrecioCompra: null,
                valueDescuentoCompra: '0.00',
                itemDescuentoCompra: null,
                tablaproducto: [],
                openModalPrecioVenta: false,
                showModalActualizarVencimiento: false,
                consulta: {
                    almacenID: this.almacenID,
                    proveedorID: this.proveedorID,
                    productoID: '',
                    utilidad: '',
                    producto: {},
                    precioCosto: ''
                },
                tablaVentanaNuevaID: 'tablaproductocomprapos' + this.nuevaventanacompraid,
                buttonKeyPrecioVenta: 100,
                buttonKeyFechaVencimiento: 200,
                visibleDrawer: false,
                producto: {}
			}
		},

		mounted () {
            if (this.estadomodulocompra === 'AGREGAR') {
				this.getLocalStorage()
                this.enviarInformacion()
			} else if (this.estadomodulocompra === 'EDITAR') {
				this.tablaproducto = []
			}
        },

        computed: {
            ...mapGetters('configuracion', ['config']),

            Subtotal () {
                return this.calculateTotal('subtotal')
            },

            DescuentoCompra () {
                return this.calculateTotal('descuentoCompra')
            },

            ImporteTotalMoneda () {
                const importeTotal = this.calculateTotal('importeTotalMoneda')
                if (this.DescuentoCompra === 0) {
                    if (this.Subtotal !== importeTotal) {
                        utils.openNotificationWithIcon('info', 'Mensaje : ', 'Atencion !!! : No es igual el proceso del calculo, vuelva a realizar el calculo...', 'topRight')
                    }
                    return importeTotal
                }
                return importeTotal
            },

            // VIEWS PROPERTY COMPUTERS //

            ViewImporteTotalMoneda () {
                return utils.formatMoney(this.ImporteTotalMoneda, this.config.compradecimales, '.', '')
            },

            ViewSubtotal () {
                return utils.formatMoney(this.Subtotal, this.config.compradecimales, '.', '')
            },

            ViewDescuentoVenta () {
                return utils.formatMoney(this.DescuentoCompra, this.config.compradecimales, '.', '')
            }
        },

		methods: {
            calculateTotal (property) {
                const sum = this.tablaproducto.reduce((accumulator, current) => {
                    return accumulator + parseFloat(current[property])
                }, 0)
                return parseFloat(sum)
            },

            enviarInformacion () {
                this.$emit('subtotal', this.Subtotal)
                this.$emit('descuento', this.DescuentoCompra)
                this.$emit('total', this.ImporteTotalMoneda)
                this.$emit('productos', this.tablaproducto)
                this.$emit('proveedorID', this.consulta.proveedorID)
            },

            getLocalStorage () {
                const datosDB = JSON.parse(localStorage.getItem(this.tablaVentanaNuevaID))
                this.tablaproducto = datosDB === null ? [] : datosDB
                return this.tablaproducto
            },

            saveLocalStorage () {
                localStorage.setItem(this.tablaVentanaNuevaID, JSON.stringify(this.tablaproducto))
            },

            removeItemLocalStorage () {
                localStorage.removeItem(this.tablaVentanaNuevaID)
            },
            
            agregarProductoALaLista (producto) {
                this.consulta.proveedorID = producto.proveedorID
                if (this.tablaproducto.length === 0) {
                    this.agregarProductoNuevo(producto)
                } else {
                    const productoExistente = this.tablaproducto.find(element => parseInt(element.producto_id) === parseInt(producto.producto_id))
                    if (productoExistente) {
                        const index = this.tablaproducto.findIndex(element => parseInt(element.producto_id) === parseInt(producto.producto_id))
                        console.log(index)
                        this.increaseQuantity(productoExistente, index)
                    } else {
                        this.agregarProductoNuevo(producto)
                    }
                }

                this.enviarInformacion()
            },

            agregarProductoNuevo (producto) {
                this.tablaproducto.push(producto)
                // LOCAL STORAGE
                this.saveLocalStorage()
            },

            notificarProductoExistente (productoExistente) {
                utils.openNotificationWithIcon('info', 'Mensaje : ', 'El Producto ' + productoExistente.nombre + ' ' + productoExistente.medida + ' ya fue elegido...', 'topRight')
            },

            mostrarAlertaPrecioCero () {
                utils.openNotificationWithIcon('info', 'Mensaje : ', 'Por favor agregue Precios de Ventas al Producto segun la Sucursal donde pertenezca...', 'topRight')
            },
            
            // Gestion de Tabla del Producto
            validacionCantidad () {
                this.valueCantidad = (this.valueCantidad + '').replace(/[^0-9.]/g, '')
            },

            validacionDecimal () {
                this.valuePrecioCompra = (this.valuePrecioCompra + '').replace(/[^0-9.]/g, '')
            },

			validacionDecimalDescuentoCompra () {
                this.valueDescuentoCompra = (this.valueDescuentoCompra + '').replace(/[^0-9.]/g, '')
            },

            editCantidad (value) {
                const index = this.producto.index
                this.valueCantidad = value
                // Verificar si la cantidad es válida
                if (parseFloat(this.valueCantidad) === 0.000000 || this.valueCantidad === '') {
                    this.producto.cantidad = 1
                } else {
                    // Asignar la cantidad y calcular el subtotal
                    const cantidad = parseFloat(this.valueCantidad)
                    const subtotal = cantidad * parseFloat(this.tablaproducto[index].precioCompra)

                    // Redondeo según la configuración
                    const roundedSubtotal = this.config.redondeosuperiorventasubtotal === 'ACTIVADO' ? utils.redondearNumero(subtotal) : subtotal
                    const formatoSubtotal = utils.formatMoney(roundedSubtotal, this.config.compradecimales, '.', '')

                    const descuento = subtotal - parseFloat(this.valueDescuentoCompra)
                    const subtotalActualizado = this.config.redondeosuperiorventasubtotal === 'ACTIVADO' ? utils.formatMoney(utils.redondearNumero(descuento), this.config.compradecimales, '.', '') : utils.formatMoney(descuento, this.config.compradecimales, '.', '')

                    // Actualizar los valores en la tabla
                    this.tablaproducto[index].cantidad = cantidad
                    this.tablaproducto[index].subtotal = formatoSubtotal
                    this.tablaproducto[index].importeTotalMoneda = subtotalActualizado
                    
                    // Guardar en el almacenamiento local si estamos en modo de agregar
                    if (this.estadomodulocompra === 'AGREGAR') {
                        this.saveLocalStorage()
                    }

                    this.enviarInformacion()
                }
            },

            increaseQuantity (item, index) {
                item.cantidad++

                if (parseFloat(item.cantidad) !== 0.000000 || item.cantidad !== '') {
                    // Asignar la cantidad y calcular el subtotal
                    const cantidad = parseFloat(item.cantidad)
                    const subtotal = cantidad * parseFloat(this.tablaproducto[index].precioCompra)

                    // Redondeo según la configuración
                    const roundedSubtotal = this.config.redondeosuperiorventasubtotal === 'ACTIVADO' ? utils.redondearNumero(subtotal) : subtotal
                    const formatoSubtotal = utils.formatMoney(roundedSubtotal, this.config.compradecimales, '.', '')

                    const descuento = subtotal - parseFloat(this.valueDescuentoCompra)
                    const subtotalActualizado = this.config.redondeosuperiorventasubtotal === 'ACTIVADO' ? utils.formatMoney(utils.redondearNumero(descuento), this.config.compradecimales, '.', '') : utils.formatMoney(descuento, this.config.compradecimales, '.', '')

                    // Actualizar los valores en la tabla
                    this.tablaproducto[index].cantidad = cantidad
                    this.tablaproducto[index].subtotal = formatoSubtotal
                    this.tablaproducto[index].importeTotalMoneda = subtotalActualizado
                    
                    // Guardar en el almacenamiento local si estamos en modo de agregar
                    if (this.estadomodulocompra === 'AGREGAR') {
                        this.saveLocalStorage()
                    }

                    this.enviarInformacion()
                }
            },

            decreaseQuantity (item, index) {
                if (item.cantidad > 1) {
                    item.cantidad--
                    // Asignar la cantidad y calcular el subtotal
                    const cantidad = parseFloat(item.cantidad)
                    const subtotal = cantidad * parseFloat(this.tablaproducto[index].precioCompra)

                    // Redondeo según la configuración
                    const roundedSubtotal = this.config.redondeosuperiorventasubtotal === 'ACTIVADO' ? utils.redondearNumero(subtotal) : subtotal
                    const formatoSubtotal = utils.formatMoney(roundedSubtotal, this.config.compradecimales, '.', '')

                    const descuento = subtotal - parseFloat(this.valueDescuentoCompra)
                    const subtotalActualizado = this.config.redondeosuperiorventasubtotal === 'ACTIVADO' ? utils.formatMoney(utils.redondearNumero(descuento), this.config.compradecimales, '.', '') : utils.formatMoney(descuento, this.config.compradecimales, '.', '')

                    // Actualizar los valores en la tabla
                    this.tablaproducto[index].cantidad = cantidad
                    this.tablaproducto[index].subtotal = formatoSubtotal
                    this.tablaproducto[index].importeTotalMoneda = subtotalActualizado
                    
                    // Guardar en el almacenamiento local si estamos en modo de agregar
                    if (this.estadomodulocompra === 'AGREGAR') {
                        this.saveLocalStorage()
                    }

                    this.enviarInformacion()
                }
            },

            editPrecioCompra (value) {
                const index = this.producto.index
                this.valuePrecioCompra = value

                if (parseFloat(this.valuePrecioCompra) !== 0.000000 || this.valuePrecioCompra !== '') {
                    const precioCompra = parseFloat(this.valuePrecioCompra)
                    const cantidad = parseFloat(this.tablaproducto[index].cantidad)
                    const subtotal = parseFloat(cantidad * precioCompra)

                    const roundedSubtotal = this.config.redondeosuperiorventasubtotal === 'ACTIVADO' ? utils.redondearNumero(subtotal) : subtotal
                    const formatoSubtotal = utils.formatMoney(roundedSubtotal, this.config.compradecimales, '.', '')

                    const descuento = subtotal - parseFloat(this.valueDescuentoCompra)
                    const subtotalActualizado = this.config.redondeosuperiorventasubtotal === 'ACTIVADO' ? utils.formatMoney(utils.redondearNumero(descuento), this.config.compradecimales, '.', '') : utils.formatMoney(descuento, this.config.compradecimales, '.', '')

                    // Verificar si la validación de editar precio de venta está deshabilitada
                    if (this.config.deshabilitarvalidareditarprecioventa === 'ACTIVADO' || precioCompra >= parseFloat(this.tablaproducto[index].precioCompra)) {
                        // Asignar el nuevo precio de venta
                        this.tablaproducto[index].precioCompra = utils.formatMoney(precioCompra, this.config.compradecimales, '.', '')
                    } else {
                        utils.openNotificationWithIcon('info', 'Mensaje : ', 'El valor ingresado es menor al Precio de Compra actual. Por favor, vuelva a iniciar de nuevo la compra...', 'topRight')
                        return false
                    }

                    // Actualizar el subtotal en la tabla
                    this.tablaproducto[index].subtotal = formatoSubtotal
                    this.tablaproducto[index].importeTotalMoneda = subtotalActualizado

                    // Guardar en el almacenamiento local si estamos en modo de agregar
                    if (this.estadomodulocompra === 'AGREGAR') {
                        this.saveLocalStorage()
                    }

                    this.enviarInformacion()
                }
            },

            editItemDescuentoCompra (value) {
                const index = this.producto.index
                this.valueDescuentoCompra = value

                if (this.valueDescuentoCompra !== '') {
                    // Validar si el descuento es mayor que el subtotal
                    if (parseFloat(this.valueDescuentoCompra) > parseFloat(this.tablaproducto[index].subtotal)) {
                        utils.openNotificationWithIcon('info', 'Mensaje : ', 'El valor de descuento es mayor al Precio de Compra actual. Por favor, vuelva a iniciar de nuevo la compra...', 'topRight')
                        return false
                    } 
                    
                    // Asignar el nuevo descuento de venta
                    this.tablaproducto[index].descuentoCompra = utils.formatMoney(this.valueDescuentoCompra, this.config.compradecimales, '.', '')
                    
                    // Calcular el nuevo importe this.tablaproducto[i].subtotal) - parseFloat(this.valueDescuentoVentatotal en la moneda
                    const descuento = parseFloat(this.tablaproducto[index].subtotal) - parseFloat(this.valueDescuentoCompra)
                    const subtotalActualizado = this.config.redondeosuperiorventasubtotal === 'ACTIVADO' ? utils.formatMoney(utils.redondearNumero(descuento), this.config.compradecimales, '.', '') : utils.formatMoney(descuento, this.config.compradecimales, '.', '')

                    this.tablaproducto[index].importeTotalMoneda = subtotalActualizado

                    // Guardar en el almacenamiento local si estamos en modo de agregar
                    if (this.estadomodulocompra === 'AGREGAR') {
                        this.saveLocalStorage()
                    }

                    this.enviarInformacion()
                }
            },

			deleteItem (i, j) {
                this.tablaproducto.splice(i, 1)
				if (this.estadomodulocompra === 'AGREGAR') {
                	this.saveLocalStorage()
				}
                this.enviarInformacion()
                this.$root.$emit('llamarResetDescuendoMoneda')

                this.visibleDrawer = false
            },

            vaciarListaProductos () {
                this.tablaproducto = []
                localStorage.removeItem(this.tablaVentanaNuevaID)
                this.enviarInformacion()
            },

            verificarProveedor (proveedorID) {
                this.consulta.proveedorID = proveedorID
            },

            verificarSucursal (sucursalID) {
                this.consulta.sucursalID = sucursalID
            },

            // Precio de Venta y Fechas
            abrirModalAsignarPrecios (item) {
                if (parseInt(this.almacenID) !== parseInt(item.almacenId)) {
					utils.openNotificationWithIcon('info', 'Mensaje : ', 'La Sucursal que ha elegido no es igual a la sucursal donde pertenece el producto.', 'topRight')
				} else if (parseInt(this.consulta.proveedorID) !== parseInt(item.proveedorID)) {
					utils.openNotificationWithIcon('info', 'Mensaje : ', 'El Proveedor que ha elegido no es igual al proveedor donde pertenece el producto..', 'topRight')
				} else {
                    this.consulta.producto = {}

                    setTimeout(function () { 
                        this.consulta.producto = item
                        this.consulta.tipoprecioventaID = 1
                        this.consulta.almacenID = item.almacenId
                        this.consulta.productoID = item.producto_id
                        this.consulta.proveedorID = item.proveedorID
                        this.consulta.utilidad = item.porcentajeUtilidad
                        this.consulta.precioCosto = item.precioCompra
                        this.openModalPrecioVenta = true
                    }.bind(this), 300)
                    this.buttonKeyPrecioVenta++
                }
			},

            abrirModalActualizarVencimiento (item) {
                this.consulta.producto = {}

                setTimeout(function () { 
                    this.consulta.producto = item
                    this.showModalActualizarVencimiento = true
                    this.buttonKeyFechaVencimiento++
                }.bind(this), 500)
            },
            // Fechas de Vencimiento
            dataSuccessActualizarVencimiento (value) {   
            },
            // Drawer Producto
            editItem (item, index) {
                this.visibleDrawer = true
                item.index = index
                this.producto = item
            },
                
            onClose () {
                this.visibleDrawer = false
            },

            handleImageError (event) {
                event.target.src = this.imagenDefault
            }
		}
	}
</script>

<style>
    .invoice-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        border-bottom: 1px solid #e8e8e8;
        transition: background-color 0.3s;
    }

    .invoice-item:hover {
        background-color: #f7f7f7;
    }

    .item-details {
        display: flex;
        align-items: center;
        width: 80%;
    }

    .item-details-mobile {
        display: none;
        align-items: center;
        width: 80%;
    }

    .item-image {
        display: none;
    }

    .item-name {
        flex: 0 0 70%;
        word-wrap: break-word;
        color:black;
    }

    .item-quantity {
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 10%;
        color:black;
    }

    .item-actions {
        display: none;
        flex: 0 0 10%;
        justify-content: flex-end !important;
        gap: 10px;
        margin-left: 40px;
    }

    .item-total {
        font-size: 15px;
        flex: 0 0 10%;
        text-align: right;
        color:black;
        font-weight: 700;
    }

    .item-total-mobile {
        display: none;
        font-size: 15px;
        flex: 0 0 10%;
        text-align: right;
        color:black;
        font-weight: 700;
    }

    .pointer {
        cursor: pointer;
    }

    .invoice-item:hover .item-actions {
        display: flex;
        flex: 0 0 10%;
    }

    .invoice-item:hover .item-total {
        display: none;
    }

    .decrease-btn, .increase-btn {
        margin: 0 10px !important;
    }

    .drawer-total {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        color:black;
    }

    .drawer-totals-left,
    .drawer-totals-right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .nombrePrecioCompra {
        font-size:12px;
        color:#8d8d8d;
    }

    .precioCompraCarrito {
        font-size:14px;
        font-weight:700;
        color:#8f8f8f;
    }

    /* Media Queries for Mobile */
    @media (max-width: 768px) {
        .invoice-item {
            flex-direction: column;
            align-items: flex-start;
        }

        .item-details {
            display: none;
            width: 80%;
        }

        .item-details-mobile {
            display: flex;
            width: 75%;
        }

        .item-image {
            width: 55px;
            flex: 0 0 10%;
            margin-right: 10px;
            display: block;
        }

        .item-name {
            flex: 0 0 75%;
        }

        .item-total {
            display: none;
            flex: 0 0 20%;
            text-align: right;
        }

        .item-total-mobile {
            display: flex;
            flex: 0 0 30%;
            text-align: right;
            margin-left: 15px;
            color:black;
        }

        .item-quantity {
            width: 100%;
            margin-top: 5px;
            justify-content: flex-start;
        }

        .precioCompraCarrito {
            font-size:14px;
            font-weight:700;
            color:var(--element);
        }
    }
</style>