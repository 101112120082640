<template>
    <div v-if="$can('adquisicion.menu.nueva.compra')">
        <div class="facturar-container">
            <a-row :gutter="5">
                <!-- Parte izquierda -->
                <a-col :xs="24" :md="15" class="left-panel">
                    <ScrollProductoCompraMobile 
                        ref="scrollProductoMobile" 
                        :almacenid="parseInt(consulta.almacen_id)"
                        :tiposalidaid="1"
                        :tipocambioid="0"
                        :listacambio="[]"
                        @successScrollProductoCompra="successProducto"
                    />
                </a-col>

                <!-- Parte derecha -->
                <a-col :xs="24" :md="9" class="right-panel">
                    <a-card>
                        <span slot="title" style="font-size:20px;"> Nueva Compra</span>
                        <a slot="extra" href="#" @click.prevent="showDrawer"><a-icon type="control" :style="{ fontSize: '25px', color: 'black' }"/></a>
                        <div layout="vertical">
                            <a-row :gutter="3" class="scrollproductocompra">
                                <a-col :xs="24" :sm="24">
                                    <ScrollProductoCompra
                                        ref="scrollProducto" 
                                        :almacenid="parseInt(consulta.almacen_id)"
                                        :tiposalidaid="1"
                                        :tipocambioid="0"
                                        :listacambio="[]"
                                        @successScrollProductoCompra="successProducto"
                                    />
                                </a-col>
                            </a-row>
                            <div class="invoice-items">
                                <TablaProductosCompraMobile
                                    ref="tablaproductoscompramobile"
                                    :estadomodulocompra="estadomodulocompra"
                                    :nuevaventanacompraid="nuevaventanacompraid"
                                    :listaproducto="productoElegido"
                                    :almacenID="parseInt(consulta.almacen_id)"
                                    :proveedorID="parseInt(consulta.proveedor_id)"
                                    @subtotal="successSubtotal"
                                    @descuento="successDescuento"
                                    @total="successTotal"
                                    @productos="successTablaProducto"
                                    @proveedorID="successProveedorID"
                                />
                            </div>
                        </div>
                        <div class="total">
                            <div class="totals-left">
                                <p>Subtotal:</p>
                                <p>Descuento:</p>
                            </div>
                            <div class="totals-right">
                                <p>{{ ViewSubtotal }}</p>
                                <p>{{ ViewTotalDescuento }}</p>
                            </div>
                        </div>
                        <a-button type="primary" class="sell-button" size="large" @click="openModalCompra">
                              <span class="sell-button-left">Registrar</span>
                              <span class="sell-button-right">{{ ViewSubtotalTotalConDescuento }} {{ config.monedasimbolo }}</span>
                        </a-button>
                        <div class="additional-info">
                              <span class="cantidad-producto">{{ consulta.productos.length }} Productos</span>
                              <a class="cancel-link" @click="eliminarListaProductos">Cancelar</a>
                        </div>
                    </a-card>
                </a-col>
            </a-row>
        </div>

         <!--PRODUCTO PROVEEDOR-->
		<a-modal
            v-model="modalBuscarProducto"
            :title="false"
            :closable="false"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="1330"
            :zIndex="1050"
            >

            <div class="row mb-12">
                <ModuloProductoProveedor 
                    :utilidadporcentaje="config.utilidadporcentaje"
                    :statecodigobarradefault="config.statecodigobarradefault" 
                    :disabledvencimiento="config.disabledvencimiento" 
                    :preciocifradecimal="config.preciocifradecimal" 
                    :preciocifraentera="config.preciocifraentera" 
                    :precioreglatres="config.precioreglatres"  
                    :sessionuserid="config.userid"
                    :monedasimbolo="config.monedasimbolo"
                    :almacenid="config.almacenid"			
                /> 
            </div>

            <template slot="footer">
                <a-button key="back" @click="closeModalBuscarProducto()">
                    CERRAR MODAL
                </a-button>
            </template>
        </a-modal>
        <!--PRODUCTO PROVEEDOR-->

        <!--PRODUCTOS-->
		<a-modal
            v-model="modalProducto"
            title="GESTION DE PRODUCTO"
            :closable="true"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="430"
            :zIndex="1049"
            >

            <div class="row mb-12">
                <ComponenteProducto
                    :tipoproducto="producto.tipomodal"
                    :dataproducto="producto.producto"
                    :almacenid="parseInt(consulta.almacen_id)"
                    :proveedorid="consulta.proveedor_id"
                    :generarlenghtcodigobarra="config.generarlenghtcodigobarra"
                    @successProducto="dataSuccessProducto"
                />
            </div>

            <template slot="footer">
                <a-button key="back" @click="modalProducto = false">
                    CERRAR MODAL
                </a-button>
            </template>
        </a-modal>
        <!--PRODUCTOS-->

        <!--COMPRA-->
		<a-modal
            v-model="modalCompra"
            :title="false"
            :closable="false"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '50px' }"
            :width="360"
            :zIndex="1050"
            >

            <div class="row mb-12">
                <a-row>
                    <center>
                        <span style="font-size: 28px; font-weight:700; color:#006D78;">{{ ViewSubtotalTotalConDescuento }} {{ config.monedasimbolo }}</span>
                    </center><br>

                    <a-col :span="18" :offset="5">
                        <label class="button-transaccion" v-for="(item, index) in tipodecompras" :key="index">
                            <input type="radio" class="nodisplay" v-model="consulta.plancuenta_id" :value="item.plancuenta_id" :checked="item.plancuenta_id === '10'" @change="onChangeTipoCompra" />
                            <span>
                                <center><img class="img-responsive" :src="item.imagen" width="50"></center>
                                <span>{{ item.nombre_corto }}</span>
                            </span>
                        </label>
                    </a-col>

                    <a-col :span="24">
                        <span class="d-sm-block"><strong>Nro.Comprobante:</strong></span>
                        <a-input size="large" v-model="consulta.nrodocumento" placeholder="0" onfocus="myFunction(this)"/>
                    </a-col>

                    <a-col :span="24">
                        <span class="d-sm-block"><strong>Observaciones:</strong></span>
                        <a-textarea v-model="consulta.observaciones" placeholder="Observaciones de Compra" :rows="2" />
                    </a-col>

                    <a-col :span="24" v-if="habilitarcredito == true">
                        <label class="d-sm-block">Fecha de pago</label>
                        <a-date-picker
                            size="large"
                            :value="utils.getDateFormat(consulta.fechaCredito)" 
                            @change="onDateCredito"
                            :locale="locale" 
                            style="width:100%; height:44px; font-size:14px;  border-width:2px; border-color:orange; border-style:solid;"
                        /><br><br>
                    </a-col>

                    <template v-if="estadomodulocompra === 'AGREGAR'">
                        <a-col :span="24" v-if="habilitarcredito == false">
                            <br>
                            <button 
                                type="button" 
                                id="EnviarInformacion" 
                                class="btn btn-block btn-lg" 
                                style="background-color:var(--element);" 
                                @click.once="realizarCompra()"
                                :key="buttonKeyCompra"
                                >
                                <font size=4>
                                    <center>
                                        <strong style="color:white;">Registrar Ahora</strong>
                                    </center>
                                </font>
                            </button>
                        </a-col>

                        <a-col :span="24" v-if="habilitarcredito == true">
                            <button 
                                type="button" 
                                id="EnviarInformacion" 
                                class="btn btn-block btn-lg" 
                                style="background-color:#04bc84;" 
                                @click.once="realizarCompra()"
                                :key="buttonKeyCredito" 
                                >
                                <font size=4>
                                    <center>
                                        <strong style="color:white;">Realizar Credito</strong>
                                    </center>
                                </font>
                            </button>
                        </a-col>
                    </template>
                </a-row>
            </div>

            <template slot="footer">
                <a-button key="back" @click="modalCompra = false">
                    Cerrar Modal
                </a-button>
            </template>
        </a-modal>
        <!--COMPRA-->

        <a-drawer
            title="Opciones de Compra"
            placement="right"
            :closable="false"
            :mask="true"
            :maskClosable="true"
            :destroyOnClose="true"
            :visible="visibleDrawer"
            :zIndex="1040"
            :width="240"
            @close="onClose"
            >
            <a-row>
                <a-col :span="22" :offset="1">
                    <a-button 
                        v-if="$can('adquisicion.agregar.nuevo.producto.compra')"
                        size="small"
                        block
                        type="primary" 
                        @click="openModalProducto(null, 'agregar')"
                        >
                        <a-icon type="plus" /> Nuevo Producto
                    </a-button>
                    <br><br>
                </a-col>

                <a-col :span="22" :offset="1">
                    <a-button 
                        v-if="estadomodulocompra === 'AGREGAR'"
                        block 
                        type="primary" 
                        size="small" 
                        @click="openVentanaNueva()"
                        >
                        <a-icon type="desktop" /> Nueva Ventana {{ nuevaventanacompraid }}
                    </a-button>
                    <br><br>
                </a-col>

                <a-col :span="22" :offset="1">
                    <a-button 
                        v-if="$can('adquisicion.busqueda.de.producto.en.compras')"
                        size="small" 
                        block
                        @click="openModalBuscarProducto()"
                        >
                        <a-icon type="search" /> Buscar Producto
                    </a-button>
                    <br><br>
                </a-col>

                <a-col :span="22" :offset="1">
                    <a-button 
                        v-if="$can('adquisicion.boton.opciones.de.compra')" 
                        size="small" 
                        block
                        @click="habilitarOpciones()"
                        >
                        <span v-if="!isBlock"><a-icon type="lock" /> Habilitar</span>
                        <span v-if="isBlock"><a-icon type="unlock" /> Deshabilitar</span>
                    </a-button>
                    <br><br>
                </a-col>

                <a-col :span="22" :offset="1" v-if="$can('adquisicion.compra.por.sucursales')">
                    <!--SELECT ALMACEN-->
                    <span class="d-sm-block"><strong>Sucursal:</strong></span>
                    <a-select 
                        :disabled="!isBlock" 
                        :value="consulta.almacen_id" 
                        @change="onChangeAlmacen"
                        size="large" 
                        style="width: 100%"
                        >
                        <a-select-option v-for="(value, index) in almacenes" :key="index" :value="value.almacen_id"> {{ value.nombre }}</a-select-option>
                    </a-select>
                    <!--SELECT ALMACEN-->
                </a-col>

                <a-col :span="22" :offset="1">
                    <span class="d-sm-block"><strong>Proveedor:</strong></span>
                    <a-select 
                        size="large" 
                        :value="consulta.proveedor_id" 
                        @change="onChageScrollProveedor" 
                        placeholder="Seleccionar" 
                        show-search option-filter-prop="children" 
                        :filter-option="filterOptionProveedor" 
                        style="width: 100%"
                        >
                        <a-select-option v-for="(value, index) in proveedores" :key="index" :value="value.proveedor_id"> {{ value.empresa }}</a-select-option>
                    </a-select>
                </a-col>

                <a-col :span="22" :offset="1" v-if="$can('adquisicion.compra.por.usuarios')">
                    <!--SELECT USUARIO-->
                    <span class="d-sm-block"><strong>Usuario:</strong></span>
                    <a-select 
                        :disabled="!isBlock" 
                        :value="consulta.usuario" 
                        @change="onChangeUsuario"
                        size="large"
                        style="width: 100%"
                        >
                        <a-select-option v-for="(value, index) in usuarios" :key="index" :value="value.usuario"> {{ value.usuario }}</a-select-option>
                    </a-select>
                    <!--SELECT USUARIO-->
                </a-col>

                <a-col :span="22" :offset="1" v-if="$can('adquisicion.compra.por.fechas')">
                    <span class="d-sm-block"><strong>Elige la fecha:</strong></span>
                    <a-date-picker 
                        :disabled="!isBlock" 
                        :value="utils.getDateFormat(consulta.fechadecompra)" 
                        @change="onChangeFechaCompra" 
                        :locale="locale"
                        size="large"
                        style="width: 100%"
                    />
                    <br><br>
                </a-col>
            </a-row>
        </a-drawer>

        <div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1055;">
			<div style="margin-top: 250px;">
				<vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
				<br><br>
				<center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
			</div>
		</div>
    </div>
</template>

<script>
	import moment from 'moment'
    import Swal from 'sweetalert2'
	import * as utils from '@/utils/utils'
    import Cookies from 'js-cookie'
    import { mapGetters, mapActions } from 'vuex'
	import locale from 'ant-design-vue/es/date-picker/locale/es_ES'
    import 'moment/locale/es'
    import ScrollProductoCompraMobile from '../../../components/Mobile/ScrollProductoCompraMobile.vue'
    import TablaProductosCompraMobile from '../../../components/Mobile/TablaProductosCompraMobile.vue'
    import ScrollProductoCompra from '../../../components/ScrollProductoCompra.vue'
    import ComponenteProducto from '../../Inventario/Components/ComponenteProducto.vue'
	import ModuloProductoProveedor from '../../Inventario/Modulos/ModuloProductoProveedor.vue'
    import SelectScrollProveedor from '../../../components/SelectScrollProveedor.vue'

    import {
        ALMACEN_SISTEMA_KEY_ID,
        SISTEMA_USUARIO
    } from '@/utils/constants'

    export default {

        props: [
            'nuevaventanacompraid', 
            'estadomodulocompra',
            'updatecompra'
        ],

        components: {
            ScrollProductoCompraMobile,
            TablaProductosCompraMobile,
            ScrollProductoCompra,
            // TablaProductosCompra,
            ModuloProductoProveedor,
            ComponenteProducto
        },

        data () {
            return {
                dateFormat: 'YYYY-MM-DD',
                moment,
                locale,
                utils,
                productoElegido: {},
                consulta: {
                    almacen_id: '',
                    plancuenta_id: 0,
                    proveedor_id: 0,
                    tipoprecioventaID: '1',
                    usuario: 'Seleccionar',
                    tiposalida_id: 1,
                    tipo_venta_id: '1',
                    observaciones: '',
                    fechaCredito: '',
                    fechadecompra: '',
                    productos: [],
                    subtotal: '',
                    descuento: '',
                    total: '',
                    importeTotal: '',
                    descuentoPorcentajeManual: 'Seleccionar Descuento',
                    descuentoPorcentajeSeleccion: '',
                    descuentoPorcentajeNombre: '',
                    descuentoMonedaMonto: '',
                    clienteID: '',
                    clienteNombre: 'SIN NOMBRE',
                    clienteNit: '0',
                    codigoCompra: '',
                    importePagar: '',
                    cambio: '',
                    nrodocumento: 0
                },
                producto: {
                    producto: {},
                    tipomodal: ''
                },
                isBlock: false,
                spinnerloading: false,
                habilitardescuentomanualventa: false,
                habilitarcredito: false,
                modalCliente: false,
                modalBuscarProducto: false,
                modalProducto: false,
                buttonKeyCompra: 100,
                buttonKeyCredito: 200,
                buttonKeyCliente: 300,
                isDisabledButton: this.$can('manager.arqueo.de.caja'),
                visibleDrawer: false,
                modalCompra: false
            }
        },

        beforeDestroy () {
			this.$root.$off('llamarResetDescuendoMoneda', this.resetDescuentoMonedaMonto)
		},

        created () {
            this.cargarAlmacenProductos()
            this.getAlmacenListar()
            this.getProveedores()
            this.cargarUsuarios()
            this.cargarDatosCompra()
            this.cargarTipoCompra()
            this.comandkeys()
            this.$root.$on('llamarResetDescuendoMoneda', this.resetDescuentoMonedaMonto)
        },

        computed: {
            ...mapGetters('almacen', ['almacenes']),
            ...mapGetters('usuario', ['usuarios']),
            ...mapGetters('auth', ['user']),
            ...mapGetters('configuracion', ['config']),
            ...mapGetters('tipoprecioventa', ['listatipoprecioventa']),
            ...mapGetters('plancuenta', ['tipodecompras']),
            ...mapGetters('proveedor', ['proveedores']),

            totalConDescuento () {
                if (this.consulta.descuentoMonedaMonto > 0) {
                    return parseFloat(this.consulta.total) - parseFloat(this.consulta.descuentoMonedaMonto)
                }

                return parseFloat(this.consulta.subtotal)
            },

            ViewSubtotal () {
                return utils.formatMoney(this.consulta.subtotal, this.config.compradecimales, '.', '')
            },

            ViewSubtotalTotalConDescuento () {
                return utils.formatMoney(this.consulta.total, this.config.compradecimales, '.', '')
            },

            ViewTotalConDescuento () {
                return utils.formatMoney(this.totalConDescuento, this.config.compradecimales, '.', '')
            },

            ViewTotalDescuento () {
                return utils.formatMoney(this.consulta.descuento, this.config.compradecimales, '.', '')
            }
        },

        methods: {
            ...mapActions('producto', ['getListaScrollMasivoProducto']),
            ...mapActions('almacen', ['getAlmacenListar']),
            ...mapActions('usuario', ['getUsuarioListar']),
            ...mapActions('plancuenta', ['getTipoCompras']),
            ...mapActions('compra', ['getDatosCompra', 'guardarCompra']),
            ...mapActions('proveedor', ['getProveedorListado']),

            filterOptionProveedor (input, option) {
                return (
                    option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
                )
            },

            comandkeys () {
				var self = this

				var ENTER_KEY_CLOSE = 27
				var ENTER_KEY_SUB_CLOSE = 27
				var ENTER_KEY_CONFIRMAR = 13

				var ENTER_KEY_CODIGO_BARRA = 112
				var ENTER_KEY_LISTA = 113
				var ENTER_KEY_IMPORTE = 114
				var ENTER_KEY_CLIENTE = 115
				var ENTER_KEY_TIPO_SALIDA = 116
				var ENTER_KEY_AGREGAR_CLIENTE = 117
				var ENTER_KEY_AGREGAR_SOLICITANTE = 118
				var ENTER_KEY_GUARDAR_CLIENTE = 119
				var ENTER_KEY_GUARDAR_SALIDA = 120
				var ENTER_KEY_MAYUS = 16
				var ENTER_KEY_O = 79
				var ENTER_KEY_J = 74
				var ENTER_KEY_CTRL = 17
				var ENTER_KEY_PLUS = 61

				document.addEventListener('keydown', function (e) {
                    const code = e.keyCode || e.which
                    const habilitarControlCodigoBarras = self.config.habilitarcontrolcodigobarras === 'ACTIVADO'
                    const codesToPrevent = [74, 61, 17, 27, 112, 113, 114, 115, 116, 117, 118, 119, 120]

                    if ((habilitarControlCodigoBarras && codesToPrevent.includes(code)) || (!habilitarControlCodigoBarras && codesToPrevent.slice(1).includes(code))) {
                        e.preventDefault()
                        
                        switch (code) {
                            case ENTER_KEY_TIPO_SALIDA:
                                console.log('F5')
                                break
                            case ENTER_KEY_J:
                            case ENTER_KEY_PLUS:
                            case ENTER_KEY_CTRL:
                            case ENTER_KEY_CODIGO_BARRA:
                                document.getElementById('CodigoBarrasOnfocus').focus()
                                break
                            case ENTER_KEY_CLOSE:
                                document.getElementById('CodigoBarrasOnfocus').focus()
                                document.getElementById('CerrarModalCliente').click()
                                break
                            case ENTER_KEY_SUB_CLOSE:
                                document.querySelector('.swal2-cancel').click()
                                break
                            case ENTER_KEY_CONFIRMAR:
                                document.querySelector('.swal2-confirm').click()
                                document.getElementById('CodigoBarrasOnfocus').dispatchEvent(new KeyboardEvent('keypress'))
                                break
                            case ENTER_KEY_GUARDAR_SALIDA:
                                document.getElementById('EnviarInformacion').click()
                                break
                            case ENTER_KEY_LISTA:
                                document.getElementById('productoSelect').click()
                                break
                            case ENTER_KEY_IMPORTE:
                                document.getElementById('ImportePagar').focus()
                                document.getElementById('ImportePagar').select()
                                break
                            case ENTER_KEY_CLIENTE:
                                break
                            case ENTER_KEY_AGREGAR_SOLICITANTE:
                                document.getElementById('InputSolicitante').focus()
                                break
                            case ENTER_KEY_AGREGAR_CLIENTE:
                                document.getElementById('AgregarCliente').click()
                                break
                            case ENTER_KEY_GUARDAR_CLIENTE:
                                document.getElementById('GuardarCliente').click()
                                break
                            default:
                                break
                        }
                    }
                })
			},

            habilitarOpciones () {
                this.isBlock = !this.isBlock
            },

            cargarAlmacenProductos () {
				this.consulta.almacen_id = (this.user) ? this.user.almacenID.toString() : Cookies.get(ALMACEN_SISTEMA_KEY_ID)
            },

            cargarUsuarios () {
				this.consulta.usuario = (this.user) ? this.user.usuario : Cookies.get(SISTEMA_USUARIO)
				if (this.$can('adquisicion.compra.por.usuarios')) {
					this.getUsuarioListar()
				}
            },

            getProveedores () {
                this.getProveedorListado()
            },

            cargarProductosMasivos () {
                this.consulta.proveedor_id = '0'
                this.getListaScrollMasivoProducto(this.consulta)
            },

            cargarDatosCompra () {
                this.getDatosCompra(this.consulta)
                .then(response => { 
                    if (response.status === 200) {
						if (this.estadomodulocompra === 'AGREGAR') {
							this.consulta.codigoCompra = response.data.codigoCompra
						} else if (this.estadomodulocompra === 'EDITAR') {
							this.consulta.codigoCompra = this.updatecompra.codigoCompra
						}
					}
                }).catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje:', utils.showErrors(error.response.data), 'topRight')
                })
            },

            cargarTipoCompra () {
                this.getTipoCompras()
                .then(response => { 
                    this.consulta.plancuenta_id = '10'
                }).catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje:', utils.showErrors(error.response.data), 'topRight')
                })
            },
            // Seleccion de Datos

            onChangeFechaCompra (date, dateString) {
				this.consulta.fechadecompra = dateString
			},

            onChangeUsuario (value) {
				this.consulta.usuario = value
            },

            onChangeAlmacen (value) {
				this.consulta.almacen_id = value
                this.cargarProductosMasivos()

                setTimeout(function () { 
                    this.$refs.scrollProducto.verificarProductos()
                    this.$refs.scrollProductoMobile.verificarProductos()
                    this.$refs.tablaproductoscompramobile.verificarSucursal(this.consulta.almacen_id)
                }.bind(this), 700)
            },

            onChangeTipoCompra (event) {
                this.consulta.plancuenta_id = event.target.value

                if (this.consulta.plancuenta_id === '11') {
					this.habilitarcredito = true
				} else {
					this.habilitarcredito = false
				}
            },

            onDateCredito (date, dateString) {
                this.consulta.fechaCredito = dateString
            },

            // Resultado de Datos
            successProducto (value) {
                this.productoElegido = value
                this.$refs.tablaproductoscompramobile.agregarProductoALaLista(value)
            },

            successProductoCodigo (value) {
                this.productoElegido = value
                this.$refs.tablaproductoscompramobile.agregarProductoALaLista(value)
            },

            successSubtotal (value) {
                this.consulta.subtotal = value
            },

            successDescuento (value) {
                this.consulta.descuento = value
            },

            successTotal (value) {
                this.consulta.importePagar = utils.formatMoney(value, this.config.compradecimales, '.', '')
                this.consulta.total = value
            },

            successTablaProducto (value) {
                this.consulta.productos = value
            },

            onChageScrollProveedor (value) {
                this.consulta.proveedor_id = value
                this.getListaScrollMasivoProducto(this.consulta)

                setTimeout(function () { 
                    this.$refs.scrollProducto.verificarProductos()
                    this.$refs.scrollProductoMobile.verificarProductos()
                    this.$refs.tablaproductoscompramobile.verificarProveedor(this.consulta.proveedor_id)
                    this.$refs.tablaproductoscompramobile.verificarSucursal(this.consulta.almacen_id)
                }.bind(this), 700)
            },

            successProveedorID (value) {
                this.consulta.proveedor_id = value.toString()
            },

            onChangeDescuentoMoneda (value) {
                this.consulta.descuentoMonedaMonto = value
            },
            // Modales
            openVentanaNueva () {
				const ventana = parseInt(this.nuevaventanacompraid) + 1
				this.$emit('abrirNuevaVentana', ventana)
			},

            openModalBuscarProducto () {
				setTimeout(function () { 
                    this.modalBuscarProducto = true
                }.bind(this), 300)
			},

            closeModalBuscarProducto () {
                this.showModalIframeProducto = true
				this.cargarAlmacenProductos()
                this.cargarProductosMasivos()
                this.cargarDatosCompra()

				setTimeout(function () {
                    this.modalBuscarProducto = false
                    this.showModalIframeProducto = false
                }.bind(this), 500)
			},

            openModalProducto (data, tipo) {
                this.producto.producto = []
                this.producto.tipomodal = ''

                setTimeout(function () { 
                    this.producto.producto = data
                    this.producto.tipomodal = tipo
                    this.modalProducto = true
                }.bind(this), 300)
            },

            dataSuccessProducto (newValue) {
                this.cargarAlmacenProductos()
                this.cargarProductosMasivos()
                this.cargarDatosCompra()
                this.$refs.scrollProducto.verificarProductos()
                this.$refs.scrollProductoMobile.verificarProductos()
                
                setTimeout(function () { 
                    this.modalProducto = false
                }.bind(this), 300)
            }, 
            // Operaciones

            obtenerListadoActual (value) {
                this.spinnerloading = true
                this.cargarProductosMasivos()

                setTimeout(function () { 
                    this.$refs.scrollProducto.verificarProductos()
                    this.$refs.scrollProductoMobile.verificarProductos()
                    this.spinnerloading = false
                }.bind(this), 700)
            },

            realizarCompra () {
				if (Object.entries(this.consulta.productos).length === 0) {
                    Swal.fire('Atencion!', 'Por favor seleccione un producto para realizar la compra y vuelva a intentar...', 'error')
                } else if (parseInt(this.consulta.proveedor_id) === 0) {
                    Swal.fire('Atencion!', 'Por favor seleccione un proveedor para realizar la compra y vuelva a intentar...', 'error')
                } else if (this.consulta.subtotal === 0) {
                    utils.openNotificationWithIcon('warning', 'Mensaje : ', 'Por favor ingrese una Cantidad y un Precio de Compra...', 'topRight')
                    this.buttonKey++
                } else {
                    Swal.fire({
                        title: 'Desea Agregar y Guardar?',
                        text: 'Tome en cuenta que debera registrar con cuidado los campos ingresados y no debe contener datos duplicados en el sistema.',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Si, Proceder Ahora (INTRO)',
                        cancelButtonClass: 'btn btn-danger',
                        confirmButtonClass: 'btn btn-success',
                        cancelButtonText: 'No, Proceder! (ESC)'
                    }).then((result) => {
                        if (result.value) {
							this.spinnerloading = true
							this.consulta.importeTotal = this.totalConDescuento
                            this.guardarCompra(this.consulta)
                            .then(response => {
                                if (response.status === 201) {
                                    this.$refs.tablaproductoscompramobile.vaciarListaProductos()
                                    this.cargarDatosCompra()
                                    this.cargarProductosMasivos()

                                    setTimeout(function () {
                                        this.consulta.fechaCredito = ''
                                        this.consulta.proveedor_id = '0'
                                        
                                        this.$refs.scrollProducto.verificarProductos()
                                        this.$refs.scrollProductoMobile.verificarProductos()
                                        this.$refs.tablaproductoscompramobile.verificarSucursal(this.consulta.almacen_id)

                                        const compraID = parseInt(response.data.compraID)
                                        if (compraID) {
                                            if (response.data.comprobanteTermico === 'ACTIVADO') {
                                                utils.openReciboMobile(`/reporte/compra/termica/${compraID}`)
                                            } else {
                                                utils.openReciboMobile(`/reporte/compra/reporte/carta/${compraID}`)
                                            }
                                        }
                                        this.modalCompra = false
                                        this.spinnerloading = false

                                        utils.openNotificationWithIcon('success', 'Mensaje : ', response.data.message, 'topRight')
                                    }.bind(this), 500)
                                }
                            })
                            .catch(error => {
                                utils.openNotificationWithIcon('error', 'Mensaje:', utils.showErrors(error.response.data), 'topRight')
                            })
                        } else if (result.dismiss === Swal.DismissReason.cancel) {
                            Swal.fire(
                                'No Realizado',
                                'No has realizado ninguno :(',
                                'error'
                            )
                        }
                    })
                }
				this.buttonKeyCompra++
                this.buttonKeyCredito++
            },

            resetDescuentoMonedaMonto () {
                this.consulta.descuentoMonedaMonto = ''
            },

            eliminarListaProductos () {
                this.spinnerloading = true
                this.$refs.tablaproductoscompramobile.vaciarListaProductos()
                this.consulta.descuentoMonedaMonto = ''
                this.cargarDatosCompra()
                this.cargarAlmacenProductos()
                this.cargarProductosMasivos()
                setTimeout(function () {        
                    this.spinnerloading = false
                }.bind(this), 500)
            },

            showDrawer () {
                this.visibleDrawer = true
            },
                
            onClose () {
                this.visibleDrawer = false
            },

            openModalCompra () {
                this.modalCompra = true               
            }
        }
    }

</script>

<style>
/* Estilos generales */
.ant-card-body {
    padding: 1px !important;
    zoom: 1;
}

.scrollproductocompra {
    display: none;
}

.facturar-container {
  padding: 0px;
}

.right-panel .invoice-items {
    margin-top: 10px;
    height: 400px;
    max-height: 400px;
    overflow-y: auto;
}

.total {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  color:black;
}

.totals-left,
.totals-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sell-button {
  width: 100%;
  margin-top: 10px;
  display: flex;
  font-size: 18px;
  justify-content: space-between;
}

.sell-button-left {
  text-align: left;
  margin-top: 6px;
}

.sell-button-right {
  text-align: right;
  margin-top: 6px;
}

.additional-info {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.cantidad-producto {
    color:black; 
    font-size:18px;
}

.cancel-link {
    font-size:18px;
    color: #f5222d;
    font-weight:700;
    cursor: pointer;
}

.add-client-button {
  width: 100%;
}

/** Botones Transaccion */
.button-transaccion{
    margin-right: 7px;
    display: inline-block;
    appearance: none;
    line-height: 1em;
    font-weight:bold;
    padding: 40px;
    color: #fff;
    border-radius: 10px;
    outline: none;
    cursor: pointer;
    font-size: 1em;
    padding: 1.3em;
    background-color: #f9f9f9;
    color: var(--element);
    box-shadow: 0 2px 4px rgba(53, 53, 53, 0.79);
}
    
.button-transaccion:focus, .button-transaccion:hover {
    background-color: #fff;
    color: #eeeeee;
    box-shadow: inset 0 0.07px 0.3px 1px rgba(0, 0, 0, 0.2);
}

.button-transaccion:active {
    background-color: #69f;
    box-shadow: inset 0 0.07px 0.3px 1px rgba(0, 0, 0, 0.2);
}
    
.button-transaccion input:checked + span {
    color: rgb(18, 151, 6);
    border-color: rgb(18, 151, 6);
}
    
.opacity {
    position: absolute;
    opacity: 0;
    width: 0;
}
    
.visibility {
    visibility: hidden;
}
    
.nodisplay {
    display: none;
}

/* Media Queries for Mobile */
@media (max-width: 768px) {
    .scrollproductocompra {
        display: flex;
    }

    .left-panel {
        display: none;
    }

    .right-panel {
        width: 100%;
    }

    .right-panel .buttons {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 10px;
    }

    .right-panel .invoice-items {
        max-height: none;
    }
}

</style>