<template>
    <div v-if="$can('adquisicion.menu.nueva.compra')">
        <ModuloAgregarCompraPOS
            :nuevaventanacompraid="parseInt(this.ventanaID)"
            @abrirNuevaVentana="openNuevaVentana"
            :estadomodulocompra="`AGREGAR`"
            :updatecompra="[]"
        />  
    </div>
</template>
    
<script>
    import ModuloAgregarCompraPOS from './Modulos/ModuloAgregarCompraPOS.vue'
    
    export default {
        
        props: [
            'ventanaID'
        ],

        components: {
            ModuloAgregarCompraPOS
        },

        methods: {
            openNuevaVentana (value) {
                const ruta = this.$router.resolve({
                    name: 'comprapos',
                    params: { ventanaID: value }
                })
                window.open(ruta.href, '_blank')
            }
        }

    }
</script>